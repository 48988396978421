import(/* webpackMode: "eager" */ "/var/www/poiscaille/apps/shop/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/poiscaille/apps/shop/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/actions/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/actions/SocialShareButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/ad-hoc/images/locker-content-catering-dish-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/ad-hoc/images/locker-content-crustacean-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/ad-hoc/images/locker-content-cut-fish-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/ad-hoc/images/locker-content-fish-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/ad-hoc/images/locker-content-mix-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/ad-hoc/images/locker-content-shellfish-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/ad-hoc/images/poiscaille-engagements.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/ad-hoc/images/poiscaille-graphique-repartition-couts-casier-mer.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/carousel/Carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/form/block/BasicContactForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/form/block/NewsletterForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/layout/banner/BannerContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/layout/footer/FooterLinkCookiesPreferences.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","name"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/layout/navbar/NavbarInner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/newsletter-modal/NewsletterModalInner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/seafood-platter/SeafoodPlatterCalculator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/components/next-generation/video-player/VideoPlayer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/styles/images/earth.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/styles/images/fish.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/styles/images/french-flag.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/styles/images/logo-poiscaille-blanc.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/styles/images/logo-poiscaille.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/styles/images/money.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/poiscaille/apps/shop/src/app/styles/images/star.svg");
